import React from 'react'
import Wisepops from '../../../components/Integrations/IntegrationsDetails/Wisepops'
import PageSeo from '../../../components/SEO'

function index() {
    return (
        <div>
            <PageSeo pageTitle="Wisepops"/>
            <Wisepops/>
        </div>
    )
}

export default index
